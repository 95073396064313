import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ChildComponent1({ selectedDate }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/delta', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <ul>
        {data.map((item) => (
          <li key={item.id}>
            {item.v15} - {item.v8}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ChildComponent1;
