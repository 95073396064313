import React from 'react';
import Background from './background';
import Sidebar from './test';
import Data from './meter'
import Side from './test2';
import Water from './WaterDrop'
import Boxes from './Boxes';
import DatePicker from './date';
import BC from './bo'
import Overlap from './OverlappingBoxes'
import Name from './name'
import Name2 from './name2'
import Supply from './img'
import Pipe from './pipe'
import Room from './room'
import Mfd from'./meter'
import HalfProgressBar from'./HalfProgressBar'
import LineChart from './graph'
import Graph1 from './graph1'
import Pannel_temp from './graph/pannel_temp'
import Graph_air_a1 from './graph/g_air_a1'
import Graph_main_kw from './graph/main_kw'
import LineGraph3 from './graph/amCharts_room_c'
import Graph_env_e1 from './graph/g_env_e1'
import EnergyGraph from './graph/csv_graph'
import Chart from './graph/csv_graph';
import Chart1 from './graph/csv_graph1';
import Supply1  from './image2';
import ParentComponent from './CustomDatePicker'
import ChildComponent1 from './MyOtherComponent'
import LineGraph_rc from './graph/graph_rc'
import Graph_main_kwh_d from './graph/g_main_kwh_d'
// import FileSelector from './graph/csv_loader'



const App = () => {
  return (
    <div>
    
      {/* <Sidebar/> 
      
      <TopBar/>
      <Side/>
      <Supply/>
      <Mfd/>
      
             */}
      {/* <Graph_air1/> */}
      {/* <Chart1/> */}
      {/* <FileSelector/> */}
      {/* <MyComponent/> */}

      {/* 
       */}
      {/* <Graph_main_kw/> */}


      <ParentComponent/>
      <Background/>
      <Supply1/>

      

{/* 
      <Graph_main_kwh_d/> */}

      {/* <LineGraph_rc/> */}

      {/* <Chart/> */}
      {/* <Pannel_temp/>
      <Sidebar/> */}
      {/* <ChildComponent1/> */}
      {/* <Room/> */}
      {/* <Sidebar/> */}

         {/* <HalfProgressBar/> */}

      {/* <Graph_env_e1/> */}
      {/* <Graph_air_a1/> */}
      {/* <LineGraph/> */}
       {/* <Overlap/>  */}
      {/* <Graph1/> */}
      
    
       
    </div>
  );
};

export default App;
