import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

am4core.useTheme(am4themes_animated);

const Graph_main_kwh_r = () => {
  const [chartData, setChartData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Fetch data from Node.js API and set it to chartData state variable
    const fetchData = async () => {
      const response = await fetch("http://localhost:8000/g_main_kw");
      const data = await response.json();
      setChartData(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("chartdiv", am4charts.XYChart);

    // Convert date strings to Date objects and update the dt_time field to hh:mm format
    const chartDataWithTime = chartData.map((dataPoint) => ({
      ...dataPoint,
      dt_time: new Date(dataPoint.dt_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));

    // Add data to chart
    chart.data = chartDataWithTime;

    // Create X axis
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "dt_time";
    xAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value" column
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text = "";
    valueAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value1" column
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.minWidth = 50;
    valueAxis2.title.text = "";
    valueAxis2.renderer.grid.template.location = 0;
    valueAxis2.renderer.opposite = true;

    // Create series for "value" column
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value1";
    series.dataFields.categoryX = "dt_time";
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = "{value}";
    series.name = "PM-2.5";
    series.fill = am4core.color("#008FFB");
    series.stroke = am4core.color("#008FFB");

    // Create series for "value1" column
    // const series2 = chart.series.push(new am4charts.LineSeries());
    // series2.dataFields.valueY = "value1";
    // series2.dataFields.categoryX = "dt_time";
    // series2.strokeWidth = 2;
    // series2.yAxis = valueAxis;
    // series2.minBulletDistance = 10;
    // series2.tooltipText = "{value1}";
    // series2.name = "Humidity";
    // series2.fill = am4core.color("#FF4560");
    // series2.stroke = am4core.color("#FF4560");

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "bottom";

    // Add title
    const title = chart.titles.create();
    title.text = "PM-2.5";
    title.fontSize = 20;
    title.marginBottom = 20;


  
    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
  }, [chartData]);
  

  return <div id="chartdiv" style={{ width: "100%", height: "530px",zIndex: 7 }} />;
};

export default Graph_main_kwh_r;


