import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import "./meter.css";
import Graph_main_kw1 from './graph/g_main_kw'
import Graph_main_kwh_r from './graph/g_main_kwh_r'
import Graph_main_kwh_d from './graph/g_main_kwh_d'
import Graph_chiller_kw from './graph/g_chiller_kw'
import Graph_solar_kw from './graph/g_solar_kw'


const Meter = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    setShowGraph4(!showGraph4);
  };
  const handleGraphClick5 = () => {
    setShowGraph5(!showGraph5);
  };
  const handleGraphClick6 = () => {
    setShowGraph6(!showGraph6);
  };

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/mfd', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
        {showGraph1 && data.length > 0 && (
        <Graph_main_kw1 selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_chiller_kw selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <Graph_solar_kw selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    <div className="meter_m_a">
    {data.length > 0 && (
    <div className="outer-box_m_a">
      <div className="title_m_a">Main</div>
      <div className="bottom-left-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>KW</div>
      <div className="bottom-left-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>KWH_R</div>
      <div className="bottom-left-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>KWH_D</div>
      <div className="bottom-right-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>
      {data[0].kwh_d}
      </div>
      <div className="bottom-right-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}> 
      {data[0].kwh_r}
      </div>
      <div className="bottom-right-box_m_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}> 
      {data[0].kw}
      </div>
    </div>
    )}
    </div>
    <div className="meter_m_b">
    {data.length > 0 && (
    <div className="outer-box_m_b">
      <div className="title_m_b" >Chiller</div>
      <div className="bottom-left-box_m_b" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>KW</div>
      <div className="bottom-left-box_m_b" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>KWH</div>
      
      <div className="bottom-right-box_m_b" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>
      {data[1].kwh}
      </div>
      <div className="bottom-right-box_m_b" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>
      {data[1].kw}
      </div>
      
    </div>
    )}
    </div>
    <div className="meter_m_c">
    {data.length > 0 && (
    <div className="outer-box_m_c">
      <div className="title_m_c">Solar</div>
      <div className="bottom-left-box_m_c" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>KW</div>
      <div className="bottom-left-box_m_c" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>KWH</div>
      
      <div className="bottom-right-box_m_c" onClick={handleGraphClick3}style={{ cursor: "pointer" }}>
      {data[2].kwh}
      </div>
      <div className="bottom-right-box_m_c" onClick={handleGraphClick3}style={{ cursor: "pointer" }}>
      {data[2].kw}
      </div>
      
    </div>
    )}
    </div>
    <div className="meter_m_d">
    {data.length > 0 && (
    <div className="outer-box_m_d">
      <div className="title_m_d">Home</div>
      <div className="bottom-left-box_m_d">KW</div>
      <div className="bottom-left-box_m_d">KWH</div>
      
      <div className="bottom-right-box_m_d">
      {data[2].cc}
      </div>
      <div className="bottom-right-box_m_d">
      {data[2].home}
      </div>
      
    </div>
    )}
    </div>
    
    </div>
  );
};

export default Meter;
