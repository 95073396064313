import React, { useState, useEffect } from "react";
import axios from 'axios';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

am4core.useTheme(am4themes_animated);

const LineGraph2 = ({ selectedDate }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetchData();
    // Fetch data from Node.js API and set it to chartData state variable
  }, [selectedDate]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/g_room_2', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setChartData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (chartData.length === 0) return; // Skip chart creation if data is not available

    // Create chart instance
    const chart = am4core.create("chartdiv_b", am4charts.XYChart);

    // Convert date strings to Date objects and update the dt_time field to hh:mm format
    const chartDataWithTime = chartData.map((dataPoint) => ({
      ...dataPoint,
      dt_time: new Date(dataPoint.dt_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));

    // Add data to chart
    chart.data = chartDataWithTime;

    // Create X axis
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "dt_time";
    xAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value" column
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text = "";
    valueAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value1" column
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.minWidth = 50;
    valueAxis2.title.text = "";
    valueAxis2.renderer.grid.template.location = 0;
    valueAxis2.renderer.opposite = true;

    // Create series for "value" column
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "dt_time";
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = "{value}";
    series.name = "Temperature";
    series.fill = am4core.color("#008FFB");
    series.stroke = am4core.color("#008FFB");

    // Create series for "value1" column
    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value1";
    series2.dataFields.categoryX = "dt_time";
    series2.strokeWidth = 2;
    series2.yAxis = valueAxis;
    series2.minBulletDistance = 10;
    series2.tooltipText = "{value1}";
    series2.name = "Humidity";
    series2.fill = am4core.color("#FF4560");
    series2.stroke = am4core.color("#FF4560");

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "bottom";

    // Add title
    const title = chart.titles.create();
    const formattedDate = format(selectedDate, "dd-MM-yyyy");
    console.log()
    title.text = `Balcony - ${formattedDate}`;
    title.fontSize = 20;
    title.marginBottom = 20;

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();

    // Return cleanup function to dispose the chart
    return () => {
      chart.dispose();
    };
  }, [chartData, selectedDate]);

  return <div id="chartdiv_b" style={{ width: "100%", height: "530px" }} />;
};

export default LineGraph2;

