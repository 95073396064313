import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import "./image2.css";
import Graph_pressure from './graph/g_pressure_v1'
import Graph_c_flow from './graph/g_c_flow_v11'
import Graph_s_flow from './graph/g_s_flow_v2'
import Graph_s_t from './graph/g_s_t_v6'
import Graph_r_t from './graph/g_r_t_v7'


const Supply1 = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    if (data.length > 0) {
      setShowGraph4(!showGraph4);
    }
  };
  const handleGraphClick5 = () => {
    if (data.length > 0) {
      setShowGraph5(!showGraph5);
    }
  };
  const handleGraphClick6 = () => {
    setShowGraph6(!showGraph6);
  };

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/delta', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
          
          {showGraph1 && data.length > 0 && (
        <Graph_pressure selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_c_flow selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <Graph_s_flow selectedDate={selectedDate} />
      )}

{showGraph4 && data.length > 0 && (
        <Graph_s_t selectedDate={selectedDate} />
      )}
          {showGraph5 && data.length > 0 && (
        <Graph_r_t selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    
    
    <div className="sup_bb">
    <div className="outer-box_bb">
      <div className="title_b">ΔT</div>
      
    </div>
    </div>
    <div className="sup_cc">
    <div className="outer-box_cc">
      <div className="title_c">COP</div>
      
      
    </div>
    </div>
    <div className="sup_dd">
    <div className="outer-box_dd">
      <div className="title_d">BTU/hr</div>
    </div>
    </div>
    
    </div>
  );
};

export default Supply1;
