import React, { useEffect, useState, useRef  } from "react";
import axios from "axios";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Graph_delta from './graph/g_delta'
import Graph_btu from './graph/g_btu'
import Graph_cop from './graph/g_cop'

const ProgressBar = ({ progress, minValue, maxValue, text }) => {
  const value = Math.min(Math.max(progress, minValue), maxValue);
  const percentage = ((value - minValue) / (maxValue - minValue)) * 100;

  return (
    <div
      style={{
        position: "absolute",
        top: "235%",
        left: "52%",
        transform: "translate(-50%, -50%)",
        zIndex: 7,
        color:"white",
      }}
    >
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={10}
        styles={{
          root: {
            position: "relative",
          },
          trail: {
            stroke: "#eee",
            strokeWidth: 10,
            strokeLinecap: "round",
          },
          path: {
            stroke: "#007bff",
            strokeLinecap: "round",
            transition: "stroke-dashoffset 0.5s ease 0s",
            transform: "rotate(90deg)",
            transformOrigin: "center center",
            strokeWidth: 10,
          },
          background: {
            fill: "#fff",
          },
          text: {
            fontSize: "40px",
            fontWeight: "bold",
            fill: "#000",
            dominantBaseline: "middle",
            textAnchor: "middle",
          },
          value: {
            fontSize: "32px",
            fontWeight: "bold",
            fill: "#000",
            dominantBaseline: "middle",
            textAnchor: "middle",
          },
        }}
      >
        <div style={{fontSize: 'x-large',color:"black"}}>{text}</div>
        <div style={{fontSize: 'xx-large',color:"black"}}>{value}</div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const HalfProgressBar = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  // const handleGraphClick4 = () => {
  //   if (data.length > 0) {
  //     setShowGraph4(!showGraph4);
  //   }
  // };
  // const handleGraphClick5 = () => {
  //   if (data.length > 0) {
  //     setShowGraph5(!showGraph5);
  //   }
  // };
  // const handleGraphClick6 = () => {
  //   if (data.length > 0) {
  //     setShowGraph6(!showGraph6);
  //   }
  // };

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/delta', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
     <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
        {showGraph1 && data.length > 0 && (
        <Graph_delta selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_btu selectedDate={selectedDate} />
      )}
      {showGraph3 && data.length > 0 && (
        <Graph_cop selectedDate={selectedDate} />
      )}
          
        </div>
      </div>
      </div>
    <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "visible" }}>
      {data.map((item) => (
        <div
          key={item.id}
          style={{
            position: "absolute",
            width: "14%",
            height: "14%",
            // top: item.top,
            top: '31%',
            left: '44.5%',
            cursor: "pointer"
          }} onClick={handleGraphClick1}
          
        >
          <ProgressBar
          
            progress={item.v15}
            minValue={-20}
            maxValue={12}
            fillColor='#007bff'
            onClick={handleGraphClick1}
          />
        </div>
      ))}
    </div>
    <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "visible" }}>
      {data.map((item) => (
        <div
          key={item.id}
          style={{
            position: "absolute",
            width: "14%",
            height: "14%",
            // top: item.top,
            top: '31%',
            left: '52.5%',
            cursor: "pointer"
          }} onClick={handleGraphClick2}
        >
          <ProgressBar
          
            progress={item.v8}
            minValue={0}
            maxValue={5000000000000000}
           
            fillColor='#007bff'
            onClick={handleGraphClick2}
          />
        </div>
      ))}
    </div>
    
    <div style={{ position: "absolute", width: "100%", height: "100%", overflow: "visible" }}>
      {data.map((item) => (
        <div
          key={item.id}
          style={{
            position: "absolute",
            width: "14%",
            height: "14%",
            // top: item.top,
            top: '31%',
            left: '60.8%',
            cursor: "pointer"
          }} onClick={handleGraphClick3}
        >
          <ProgressBar
          
            progress={item.v9}
            minValue={0}
            maxValue={5000}
           
            fillColor='#007bff'
            onClick={handleGraphClick3}
          />
        </div>
      ))}
    </div>
    </div>
  );
};

export default HalfProgressBar;
