import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import './test.css';
import Graph_air_a1 from './graph/g_air_a1';
import Graph_air_a2 from './graph/g_air_a2';
import Graph_air_a3 from './graph/g_air_a3';
import Graph_air_a4 from './graph/g_air_a4';
import Graph_air_a5 from './graph/g_air_a5';
import Graph_air_a6 from './graph/g_air_a6';



function Sidebar({ selectedDate }) {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    if (data.length > 0) {
      setShowGraph4(!showGraph4);
    }
  };
  const handleGraphClick5 = () => {
    if (data.length > 0) {
      setShowGraph5(!showGraph5);
    }
  };
  const handleGraphClick6 = () => {
    if (data.length > 0) {
      setShowGraph6(!showGraph6);
    }
  };

  useEffect(() => {
    fetchData();


    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/air', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
        {showGraph1 && data.length > 0 && (
        <Graph_air_a1 selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_air_a2 selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <Graph_air_a3 selectedDate={selectedDate} />
      )}

          {showGraph4 && data.length > 0 && (
        <Graph_air_a4 selectedDate={selectedDate} />
      )}
          {showGraph5 && data.length > 0 && (
        <Graph_air_a5 selectedDate={selectedDate} />
      )}
          
          {showGraph6 && data.length > 0 && (
        <Graph_air_a6 selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    <div className="sidebar">
      <h4 className='centered' >AC-2</h4>
      <h4 className='centered' >Factor</h4>
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>PM-1
          </div>
          <div className="box-value" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>{item.a1}µm</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>PM-2.5</div>
          <div className="box-value" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>{item.a2}µm</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>PM-10</div>
          <div className="box-value" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>{item.a3}µm</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>AQ</div>
          <div className="box-value" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>GOOD</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>Pin</div>
          <div className="box-value" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>{item.a5}mm</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>Pout</div>
          <div className="box-value" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>{item.a6}mm</div>
          <p></p>
        </div>
        
      ))}
    </div>
    </div>
  );
}

export default Sidebar;