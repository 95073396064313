import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

am4core.useTheme(am4themes_animated);

const Graph_main_kwh_d = () => {
  const [chartData, setChartData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState("hourly_kwh_table");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    // Fetch data from Node.js API and set it to chartData state variable
    const fetchData = async () => {
      const response = await fetch(`http://localhost:8000/rc_graph?table=${selectedTable}&date=${selectedDate}`);
      const data = await response.json();
      setChartData(data);
    };
    fetchData();
  }, [selectedTable, selectedDate]);

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("chartdiv", am4charts.XYChart);

    // Convert date strings to Date objects and update the dt_time field to hh:mm format
    const chartDataWithTime = chartData.map((dataPoint) => ({
      ...dataPoint,
      dt_time: new Date(dataPoint.dt_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));

    // Add data to chart
    chart.data = chartDataWithTime;

    // Create X axis
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "dt_time";
    xAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value" column
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text = "";
    valueAxis.renderer.grid.template.location = 0;

    // Create Y axis for "value1" column
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.minWidth = 50;
    valueAxis2.title.text = "";
    valueAxis2.renderer.grid.template.location = 0;
    valueAxis2.renderer.opposite = true;

    // Create series for "value" column
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "dt_time";
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = "{value}";
    series.name = "KWH_Rec";
    series.fill = am4core.color("#008FFB");
    series.stroke = am4core.color("#008FFB");

    const series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "value1";
    series1.dataFields.categoryX = "dt_time";
    series1.strokeWidth = 2;
    series1.minBulletDistance = 10;
    series1.tooltipText = "{value}";
    series1.name = "KWH_Del";
    series1.fill = am4core.color("#008FFB");
    series1.stroke = am4core.color("#008FFB");

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "dt_time";
    series2.strokeWidth = 2;
    series2.minBulletDistance = 10;
    series2.tooltipText = "{value}";
    series2.name = "WH_1";
    series2.fill = am4core.color("#008FFB");
    series2.stroke = am4core.color("#008FFB");

    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = "value3";
    series3.dataFields.categoryX = "dt_time";
    series3.strokeWidth = 2;
    series3.minBulletDistance = 10;
    series3.tooltipText = "{value}";
    series3.name = "WH_2";
    series3.fill = am4core.color("#008FFB");
    series3.stroke = am4core.color("#008FFB");

    const series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.valueY = "value4";
    series4.dataFields.categoryX = "dt_time";
    series4.strokeWidth = 2;
    series4.minBulletDistance = 10;
    series4.tooltipText = "{value}";
    series4.name = "WH_3";
    series4.fill = am4core.color("#008FFB");
    series4.stroke = am4core.color("#008FFB");

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.position = "bottom";

    // Add title
    const title = chart.titles.create();
    title.text = "Hourly Graph";
    title.fontSize = 20;
    title.marginBottom = 20;

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
  }, [chartData]);

  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="tableSelect">Select Table:</label>
        <select id="tableSelect" value={selectedTable} onChange={handleTableChange}>
          <option value="hourly_kwh_table">Rc Plasto</option>
          <option value="hourly_kwh_table">hourly_kwh_table</option>
          <option value="hourly_kwh_table">hourly_kwh_table</option>
        </select>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="datePicker">Select Date:</label>
        <input
          type="date"
          id="datePicker"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
      <div id="chartdiv" style={{ width: "100%", height: "530px", zIndex: 7 }} />
    </div>
  );
};

export default Graph_main_kwh_d;

