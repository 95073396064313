import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

function Chart1() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [deviceId, setDeviceId] = useState("3");
  const [columnIndex, setColumnIndex] = useState("4");
  const [availableDeviceIds, setAvailableDeviceIds] = useState([]);
  let chart;

  const deviceNames = {
    "1": "315 INCOMER (Boys hostel Feeder)",
    "2": "200 INCOMER",
    "4": "Ganesh + Shivaji",
    "7": "Paramhans",
    "8": "Vivekanand AC",
    "9": "Vivekanand Light",
    "10": "Sai",
    "11": "STP",
    
    // "29": "Fire Panel",
    // "30": "TR 3 Load Panel 2",
    // "31": "New Pump House",
    // "32": "MICU AC",
    // "33": "LIFT + MICU + New Dialysis",
    // "35": "H.O Hostel",
    // "36": "Street Light TR3",
    // "37": "NRI Boys",
    // "38": "Vindhyanchal A Feeder",
    // "39": "Physiotherapy College",
    // "40": "PICU +NICU",
    // "41": "Old ICU",
    // "211": "Gangasagar Feeder Panel",
    // "212": "MBBS Feeder Panel",
    // "213": "Met Hall",
    // "214": "Harmony Hall",
  }

  useEffect(() => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = selectedDate.toLocaleDateString('en-GB', options).replace(/\//g, '-');
    const filePath = `./AV7/${formattedDate}_AV7.csv`;
    console.log('Selected Date:', formattedDate);
    console.log('File Path:', filePath);
    fetch(filePath)
      .then(response => response.text())
      .then(data => {
        let rows = data.split('\n');
        let deviceIds = [];
        // Extract available device IDs from the 3rd column of the CSV file
        for (let i = 1; i < rows.length; i++) {
          let row = rows[i].split(',');
          if (!deviceIds.includes(row[2])) {
            deviceIds.push(row[2]);
          }
        }
        setAvailableDeviceIds(deviceIds);

        let chartData = [];
        for (let i = 1; i < rows.length; i++) {
          let row = rows[i].split(',');
          if (row[2] === deviceId) {
            chartData.push({
              date: new Date(row[0]),
              value: Number(row[columnIndex])
            });
          }
        }
        console.log('Chart Data:', chartData);

        am4core.useTheme(am4themes_animated);
        chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.data = chartData;
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{value}";
        chart.cursor = new am4charts.XYCursor();
      })
      .catch(error => console.log(error));

    // Dispose of the chart when the component unmounts
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [selectedDate, deviceId, columnIndex]);



  return (
    <div>
      <div>
        <label htmlFor="selectedDate">Select Date:</label>
        <DatePicker
          id="selectedDate"
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
       <div>
        <label htmlFor="deviceId">Select Device ID:</label>
        <select id="deviceId" value={deviceId} onChange={(e) => setDeviceId(e.target.value)}>
          {availableDeviceIds.map(id => (
            <option key={id} value={id}>Device {id} ({deviceNames[id]})</option>
          ))}
        </select>
      </div>
      <div>
  <label htmlFor="columnIndex">Select Column:</label>
  <select id="columnIndex" value={columnIndex} onChange={(e) => setColumnIndex(e.target.value)}>
    <option value="4">kW_Total</option>
    <option value="5">kW_R_phase</option>
    <option value="6">kW_Y_phase</option>
    <option value="7">kW_B_phase</option>
    <option value="8">VAR_Total</option>
    <option value="9">VAR_R_phase</option>
    <option value="10">VAR_Y_phase</option>
    <option value="11">VAR_B_phase</option>
    <option value="12">PF_Ave</option>
    <option value="13">PF_R_phase</option>
    <option value="14">PF_Y_phase</option>
    <option value="15">PF_B_phase</option>
    <option value="16">VA_total</option>
    <option value="17">VA_R_phase</option>
    <option value="18">VA_Y_phase</option>
    <option value="19">VA_B_phase</option>
    <option value="20">VLL_average</option>
     <option value="21">Vry_phase</option>
     <option value="22">Vyb_phase</option>
     <option value="23">Vbr_phase</option>
     <option value="24">VLN_average</option>
     <option value="25">V_R_phase</option>
     <option value="26">V_Y_phase</option>
     <option value="27">V_B_phase</option>
     <option value="28">Current_Average</option>
     <option value="29">Current_R_phase</option>
     <option value="30">Current_Y_phase</option>
     <option value="31">Current_B_phase</option>
     <option value="32">Frequency</option>
     <option value="33">kWh_Received</option>
     <option value="34">VAh_Received</option>
     <option value="35">VARh_Ind._Received</option>
     <option value="36">VARh_Cap._Received</option>
     <option value="37">kWh_Delivered</option>
     <option value="38">VAh_Delivered</option>
     <option value="39">VARh_Ind._Delivered</option>
     <option value="40">VARh_Cap._Delivered</option>
     <option value="41">Reserved</option>
     <option value="42">Reserved</option>
     <option value="43">Reserved</option>
     <option value="44">Reserved</option>
     <option value="45">Reserved</option>
     <option value="46">Voltage_R_Harm</option>
     <option value="47">Voltage_Y_Harm</option>
     <option value="48">Voltage_B_Harm</option>
     <option value="49">Current_R_Harm</option>
     <option value="50">Current_Y_Harm</option>
     <option value="51">Current_B_Harm</option>
     <option value="52">kWh received R phase</option>
     <option value="53">kWh received Y phase</option>
     <option value="54">kWh received B phase</option>
     <option value="55">kVAh received R phase</option>
     <option value="56">kVAh received Y phase</option>

     <option value="57">kVAh received B phase</option>
     <option value="58">kVArh inductive received R phase</option>
     <option value="59">kVArh inductive received Y phase</option>
     <option value="60">kVArh inductive received B phase</option>
     <option value="61">kVArh capacitive received R phase</option>
    
     <option value="62">kVArh capacitive received Y phase</option>
     <option value="63">kVArh capacitive received B phase</option>
     <option value="64">PF average received R phase</option>
     <option value="65">PF average received Y phase</option>
     <option value="66">PF average received B phase</option>
     <option value="67">A average received R phase</option>
     <option value="68">A average received Y phase</option>
     <option value="69">A average received B phase</option>
     <option value="70">kWh delivered R phase</option>
     <option value="71">kWh delivered Y phase</option>
     <option value="72">kWh delivered B phase</option>
     <option value="73">kVAh delivered R phase</option>
     <option value="74">kVAh delivered R phase</option>
     <option value="75">kVAh delivered R phase</option>
     <option value="76">kVArh inductive delivered R phase</option>
     <option value="77">kVArh inductive delivered Y phase</option>
     <option value="78">kVArh inductive delivered B phase</option>
     <option value="79">kVArh capacitive delivered R phase</option>
     <option value="80">kVArh capacitive delivered Y phase</option>
     <option value="81">kVArh capacitive delivered B phase</option>
    
    {/* Add more options here */}
  </select>
</div>
      <div id="chartdiv" style={{ width: "100%", height: "530px" }}></div>
    </div>
  );
}

export default Chart1;
