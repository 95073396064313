import React from 'react';
import './Background.css'; // Import CSS file for styling
import myImage from 'C:/Users/Chetan/Downloads/log.png';
import myImage1 from 'C:/Users/Chetan/Downloads/ve.png';
import myImage2 from 'C:/Users/Chetan/Downloads/faa.png';
import myImage4 from 'C:/Users/Chetan/Downloads/fan.png';
import myImage5 from 'C:/Users/Chetan/Downloads/tank.png';

const Background = () => {
  return (
    <div >
      <div className="box1"><img className="image" src={myImage} alt="My Image" /> </div>
      {/* <div className="box12"><img className="pump" src={myImage3}/></div> */}
      <div className="box3"><h3 className="title"></h3></div>
      <div className="box10"></div>
      <div className="box5"><h2 className="centered_title"></h2></div>
      <div className="box11"> </div>
      {/* <div className="boxa"><div class='chi'>Chiller I/p<div className='boxaa'>F</div></div><div className='cha'>hh</div></div> */}
      <div className="box11_1"></div>
      <div className="box11_11"></div>
      <div className="box11_3"></div>
      <div className="box11_3_1"></div>
      <div className="box11_3_2"></div>
      <div className="box11_3_3"></div>
      <div className="box11_3_4"></div>
      <div className="box11_3_5"></div>
      <div className="box11_3_6"></div>
      <div className="box11_3_7"></div>
      <div className="box11_3_8"></div>
      <div className="box11_3_9"></div>
      <div className="box11_3_10"></div>
      <div className="box11_2"><img className="image_fan" src={myImage4} alt="My Image" /> </div>
      <div className="box11_2_1"><img className="image_fan" src={myImage4} alt="My Image" /> </div>
      <div className="box7"><img className="image_tank" src={myImage5} alt="My Image" />TES</div>
      <div className="box8"></div>
      <div className="box9"></div>
      
    </div>
  );
};

export default Background;
