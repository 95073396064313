import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import "./room.css";
import LineGraph1 from "./graph/amcharts_room_a";
import LineGraph3 from "./graph/amCharts_room_c";
import LineGraph2 from "./graph/amcharts_room_b";
import LineGraph4 from "./graph/amcharts_room_d";
import LineGraph5 from "./graph/amcharts_room_e";
import LineGraph6 from "./graph/amcharts_room_f";
import LineGraph7 from "./graph/amcharts_room_g";
import LineGraph8 from "./graph/amcharts_room_h";

const Room = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const [showGraph7, setShowGraph7] = useState(false);
  const [showGraph8, setShowGraph8] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    if (data.length > 0) {
      setShowGraph4(!showGraph4);
    }
  };
  const handleGraphClick5 = () => {
    if (data.length > 0) {
      setShowGraph5(!showGraph5);
    }
  };
  const handleGraphClick6 = () => {
    if (data.length > 0) {
      setShowGraph6(!showGraph6);
    }
  };

  const handleGraphClick7 = () => {
    if (data.length > 0) {
      setShowGraph7(!showGraph7);
    }
  };
  const handleGraphClick8 = () => {
    if (data.length > 0) {
      setShowGraph8(!showGraph8);
    }
  };

  
 

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
        setShowGraph7(false);
        setShowGraph8(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/users', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getBoxColor = (value) => {
    if (value === 0) {
      return "#e42d2d"; // Red color
    } else if (value === 1) {
      return "rgb(184, 248, 174)"; // Green color
    } else {
      return "rgb(236 239 236)"; // Default color
    }
  };

  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">

        <div className="popup-content">
        {showGraph1 && data.length > 0 && (
        <LineGraph1 selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <LineGraph2 selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <LineGraph3 selectedDate={selectedDate} />
      )}

{showGraph4 && data.length > 0 && (
        <LineGraph4 selectedDate={selectedDate} />
      )}
          {showGraph5 && data.length > 0 && (
        <LineGraph5 selectedDate={selectedDate} />
      )}
          
          {showGraph6 && data.length > 0 && (
        <LineGraph6 selectedDate={selectedDate} />
      )}
          {showGraph7 && data.length > 0 && (
        <LineGraph7 selectedDate={selectedDate} />
      )}
          {showGraph8 && data.length > 0 && (
        <LineGraph8 selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    <div className="boxes_r_a">
    {data.length > 0 && (
          <div className="sup_r_a">
            <div
              className="outer-box_r_a"
              style={{ backgroundColor: getBoxColor(data[0].v1) }}
            >
              <div className="title_r_a">{data[0].device_name}</div>
              <div className="bottom-left-box_r_a">Rh(%)</div>
              <div className="bottom-left-box_r_a">T(°C)</div>
              <div className="bottom-right-box_r_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>{data[0].v2}</div>
              <div className="bottom-right-box_r_a" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>{data[0].v3}</div>
            </div>
          </div>
    )}
    </div>
    <div className="boxes_r_b">
      {data.length > 0 && (
    <div className="sup_r_b">
    <div className="outer-box_r_b"
    style={{ backgroundColor: getBoxColor(data[1].v1) }}>
      <div className="title_r_b">{data[1].device_name}</div>
      <div className="bottom-left-box_r_b">Rh(%)</div>
      <div className="bottom-left-box_r_b">T(°C)</div>
     <div className="bottom-right-box_r_a" onClick={handleGraphClick2} style={{ cursor: "pointer" }} >{data[1].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>{data[1].v3}</div>
      
      
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_c">
      {data.length > 0 && (
    <div className="sup_r_c">
    <div className="outer-box_r_c" style={{ backgroundColor: getBoxColor(data[2].v1) }}>
      <div className="title_r_c">{data[2].device_name}</div>
      <div className="bottom-left-box_r_c">Rh(%)</div>
      <div className="bottom-left-box_r_c">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick3} style={{ cursor: "pointer" }} >{data[2].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>{data[2].v3}</div>
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_d">
      {data.length > 0 && (
    <div className="sup_r_d">
    <div className="outer-box_r_d" style={{ backgroundColor: getBoxColor(data[3].v1) }}>
      <div className="title_r_d">{data[3].device_name}</div>
      <div className="bottom-left-box_r_d">Rh(%)</div>
      <div className="bottom-left-box_r_d">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick4} style={{ cursor: "pointer" }} >{data[3].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>{data[3].v3}</div>
      
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_e">
      {data.length > 0 && (
    <div className="sup_r_e">
    <div className="outer-box_r_e" style={{ backgroundColor: getBoxColor(data[4].v1) }}>
      <div className="title_r_e">{data[4].device_name}</div>
      <div className="bottom-left-box_r_e">Rh(%)</div>
      <div className="bottom-left-box_r_e">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>{data[4].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>{data[4].v3}</div>
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_f">
      {data.length > 0 && (
    <div className="sup_r_f">
    <div className="outer-box_r_f" style={{ backgroundColor: getBoxColor(data[5].v1) }}>
      <div className="title_r_f">{data[5].device_name}</div>
      <div className="bottom-left-box_r_f">Rh(%)</div>
      <div className="bottom-left-box_r_f">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>{data[5].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>{data[5].v3}</div>
      
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_g">
      {data.length > 0 && (
    <div className="sup_r_g">
    <div className="outer-box_r_g" style={{ backgroundColor: getBoxColor(data[6].v1) }}>
      <div className="title_r_g">{data[6].device_name}</div>
      <div className="bottom-left-box_r_g">Rh(%)</div>
      <div className="bottom-left-box_r_g">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick7} style={{ cursor: "pointer" }} >{data[6].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick7} style={{ cursor: "pointer" }}>{data[6].v3}</div>
      
    </div>
    </div>
    )}
    </div>
    <div className="boxes_r_h">
      {data.length > 0 && (
    <div className="sup_r_h">
    <div className="outer-box_r_h" style={{ backgroundColor: getBoxColor(data[7].v1) }}>
      <div className="title_r_h">{data[7].device_name}</div>
      <div className="bottom-left-box_r_h">Rh(%)</div>
      <div className="bottom-left-box_r_h">T(°C)</div>
      <div className="bottom-right-box_r_a" onClick={handleGraphClick8}  style={{ cursor: "pointer" }}>{data[7].v2}</div>
      
      <div className="bottom-right-box_r_a" onClick={handleGraphClick8} style={{ cursor: "pointer" }}>{data[7].v3}</div>
      
    </div>
    </div>
    )}
    </div>

    {/* <div className="boxes_m_a">
      {data.length > 0 && (
    <div className="sup_m_a">
    <div className="outer-box_m_a">
      <div className="title_m_a">Main</div>
      <div className="bottom-left-box_m_a">KW</div>
      <div className="bottom-left-box_m_a">{data[7].v2}</div>
      <div className="bottom-right-box_m_a">{data[7].v3}</div>
      <div className="bottom-right-box_m_a">KWH</div>
      
    </div>
    </div>
    )}
    </div> */}
    </div>
  );
};

export default Room;
