import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

am4core.useTheme(am4themes_animated);

const Pannel_temp = ({ selectedDate }) => {
  const [chartData, setChartDat,setData] = useState([]);

  useEffect(() => {
    fetchData();
    // Fetch data from Node.js API and set it to chartData state variable
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/g_env_e6', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setChartDat(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Create chart instance
    const chart = am4core.create("Pannel_temp", am4charts.XYChart);

    // Convert date strings to Date objects and update the dt_time field to hh:mm format
    const chartDataWithTime = chartData.map((dataPoint) => ({
      ...dataPoint,
      dt_time: new Date(dataPoint.dt_time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }));

    // Add data to chart
    chart.data = chartDataWithTime;

    // Create X axis
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "dt_time";
    xAxis.renderer.grid.template.disabled = true;

    // Create Y axis for "value" column
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text = "";
    valueAxis.renderer.grid.template.disabled = true;

    // Create Y axis for "value1" column
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.minWidth = 50;
    valueAxis2.title.text = "";
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.renderer.opposite = true;

    // Create series for "value" column
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "dt_time";
    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = "{value}";
    series.name = "PT-1";

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value1";
    series2.dataFields.categoryX = "dt_time";
    series2.strokeWidth = 2;
    series2.minBulletDistance = 10;
    series2.tooltipText = "{value1}";
    series2.name = "PT-2";

    // Add gradient fill to the line
    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#008FFB"), 0.2);
    gradient.addColor(am4core.color("#00E396"), 0.6);
    gradient.addColor(am4core.color("#FFEB3B"), 1);
    series.fillOpacity = 0.3;
    series.strokeOpacity = 0.8;
    series.fill = gradient;
    series.stroke = gradient;

    let gradient2 = new am4core.LinearGradient();
    gradient2.addColor(am4core.color("#008FFB"), 0.2);
    gradient2.addColor(am4core.color("#00a6e3"), 0.6);
    gradient2.addColor(am4core.color("#ff3b58"), 1);
    series2.fillOpacity = 0.3;
    series2.strokeOpacity = 0.8;
    series2.fill = gradient2;
    series2.stroke = gradient2;


    // Add legend
    const legend = new am4charts.Legend();
    legend.useDefaultMarker = true;
    legend.position = "bottom";
    legend.labels.template.fontSize = 8;
    chart.legend = legend;

    // Add title
    const title = chart.titles.create();
    title.text = "PV Temp";
    title.fontSize = 18;
    title.marginBottom = 20;
    title.marginTop = 10;
    title.fill = am4core.color("#333");

    // Add axis labels
xAxis.renderer.labels.template.fontSize = 10;
xAxis.renderer.labels.template.fill = am4core.color("#333");
valueAxis.renderer.labels.template.fontSize = 10;
valueAxis.renderer.labels.template.fill = am4core.color("#333");
valueAxis2.renderer.labels.template.fontSize = 10;
valueAxis2.renderer.labels.template.fill = am4core.color("#333");

// Add tooltip
const tooltip = series.tooltip;
tooltip.pointerOrientation = "down";
tooltip.background.fillOpacity = 0.8;
tooltip.background.strokeWidth = 0;
tooltip.fontSize = 14;
tooltip.fill = am4core.color("#333");
tooltip.label.fill = am4core.color("#fff");

  
    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.strokeOpacity = 0.6;
    chart.cursor.lineX.strokeWidth = 2;
    chart.cursor.lineY.strokeOpacity = 0.6;
    chart.cursor.lineY.strokeWidth = 2;
    chart.cursor.lineY.strokeDasharray = "4,4";
    chart.cursor.lineY.stroke = am4core.color("#333");
    chart.cursor.lineX.stroke = am4core.color("#333");
    chart.cursor.lineX.strokeDasharray = "4,4";
  }, [chartData]);
  

  return (
    <div
    id="Pannel_temp"
    style={{
      width: "20%",
      height: "33%",
      position: "absolute",
      zIndex: 6,
      top: "67%",
      transform: "translateX(-5%)", // Adjust the translation value as needed
      // left: "50%",
    }}
  />
  );
  
};

export default Pannel_temp;


