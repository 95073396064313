import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import './test2.css';
import Graph_env_e1 from './graph/g_env_e1';
import Graph_env_e2 from './graph/g_env_e2';
import Graph_env_e3 from './graph/g_env_e3';
import Graph_env_e4 from './graph/g_env_e4';
import Graph_env_e5 from './graph/g_env_e5';
import Graph_env_e6 from './graph/g_env_e6';




function Side({ selectedDate }) {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    if (data.length > 0) {
      setShowGraph4(!showGraph4);
    }
  };
  const handleGraphClick5 = () => {
    if (data.length > 0) {
      setShowGraph5(!showGraph5);
    }
  };
  const handleGraphClick6 = () => {
    if (data.length > 0) {
      setShowGraph6(!showGraph6);
    }
  };

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/env', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
        {showGraph1 && data.length > 0 && (
        <Graph_env_e1 selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_env_e2 selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <Graph_env_e3 selectedDate={selectedDate} />
      )}

          {showGraph4 && data.length > 0 && (
        <Graph_env_e4 selectedDate={selectedDate} />
      )}
          {showGraph5 && data.length > 0 && (
        <Graph_env_e5 selectedDate={selectedDate} />
      )}
          
          {showGraph6 && data.length > 0 && (
        <Graph_env_e6 selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    <div className="side">
      <h4  className='centered' >AQ</h4>
      <h4  className='centered' >Factor</h4>
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>Temp</div>
          <div className="box-value" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>{item.e1}°C</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick2} style={{ cursor: "pointer" }}><div className="water-drop">
          Humidity
    </div></div>
          <div className="box-value" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>{item.e2}</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>Light</div>
          <div className="box-value" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>{item.e3}</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>UV
</div>
          <div className="box-value" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>{item.e4}</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>PV-Top</div>
          <div className="box-value" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>{item.e5}°C</div>
          <p></p>
        </div>
        
      ))}
      {data.map((item, index) => (
        <div key={index} className="box">
          <div className="box-header" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>PV-Bottom</div>
          <div className="box-value" onClick={handleGraphClick6} style={{ cursor: "pointer" }}>{item.e6}°C</div>
          <p></p>
        </div>
        
      ))}
    </div>
    </div>
  );
}

export default Side;