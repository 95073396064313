import React, { useState } from 'react';
import ChildComponent1 from './MyOtherComponent';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import HalfProgressBar from './HalfProgressBar';
import Background from './background';
import Supply from './img';
import Mfd from './meter';
import LineGraph1 from './graph/amcharts_room_a';
import LineGraph2 from './graph/amcharts_room_b';
import LineGraph3 from './graph/amCharts_room_c';
import LineGraph4 from './graph/amcharts_room_d';
import LineGraph5 from './graph/amcharts_room_e';
import LineGraph6 from './graph/amcharts_room_f';
import LineGraph7 from './graph/amcharts_room_g';
import LineGraph8 from './graph/amcharts_room_h';
import Graph_delta from './graph/g_delta'
import Graph_btu from './graph/g_btu';
import LineChart from './graph';
import Side from './test2';
import Room from './room';
import Pannel_temp from './graph/pannel_temp';
import Graph_main_kw from './graph/main_kw';
import Sidebar from './test';
import './CustomDatePicker.css';
import Graph_cop from './graph/g_cop'

function ParentComponent() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div >
      <div style={{ position: 'absolute', top: "10%", left: "59%",zIndex:8 }}>
      <DatePicker
  selected={selectedDate}
  onChange={handleDateChange}
  dateFormat="dd/MM/yyyy"
  className="custom-datepicker"
/>
      </div>
      <div >
        <HalfProgressBar selectedDate={selectedDate} />
        <Room selectedDate={selectedDate} />
        <Mfd selectedDate={selectedDate} />
        <Supply selectedDate={selectedDate} />
        <Graph_main_kw selectedDate={selectedDate} />
        <Pannel_temp selectedDate={selectedDate} />
        <Sidebar selectedDate={selectedDate} />
        <Side selectedDate={selectedDate} />
        <Background selectedDate={selectedDate} />
        {/* <LineGraph5 selectedDate={selectedDate} />
        <LineGraph6 selectedDate={selectedDate} />
        <LineGraph7 selectedDate={selectedDate} />
        <LineGraph8 selectedDate={selectedDate} />
        <LineGraph4 selectedDate={selectedDate} /> */}
        {/* <LineGraph3 selectedDate={selectedDate} /> */}
        {/* <LineGraph2 selectedDate={selectedDate} />
        <LineGraph1 selectedDate={selectedDate} /> */}
        {/* <Graph_delta selectedDate={selectedDate} />
        <Graph_btu selectedDate={selectedDate} />
        <Graph_cop selectedDate={selectedDate} /> */}
      </div>
    </div>
  );
}

export default ParentComponent;
