import React, { useEffect, useState, useRef  } from "react";
import axios from 'axios';
import "./img.css";
import Graph_pressure from './graph/g_pressure_v1'
import Graph_c_flow from './graph/g_c_flow_v11'
import Graph_s_flow from './graph/g_s_flow_v2'
import Graph_s_t from './graph/g_s_t_v6'
import Graph_r_t from './graph/g_r_t_v7'


const Supply = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const [showGraph1, setShowGraph1] = useState(false);
  const [showGraph2, setShowGraph2] = useState(false);
  const [showGraph3, setShowGraph3] = useState(false);
  const [showGraph4, setShowGraph4] = useState(false);
  const [showGraph5, setShowGraph5] = useState(false);
  const [showGraph6, setShowGraph6] = useState(false);
  const popupRef = useRef();

  const handleGraphClick1 = () => {
    if (data.length > 0) {
      setShowGraph1(!showGraph1);
    }
  };
  const handleGraphClick2 = () => {
    if (data.length > 0) {
      setShowGraph2(!showGraph2);
    }
  };
  const handleGraphClick3 = () => {
    if (data.length > 0) {
      setShowGraph3(!showGraph3);
    }
  };
  const handleGraphClick4 = () => {
    if (data.length > 0) {
      setShowGraph4(!showGraph4);
    }
  };
  const handleGraphClick5 = () => {
    if (data.length > 0) {
      setShowGraph5(!showGraph5);
    }
  };
  const handleGraphClick6 = () => {
    setShowGraph6(!showGraph6);
  };

  useEffect(() => {
    fetchData();

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowGraph1(false);
        setShowGraph2(false);
        setShowGraph3(false);
        setShowGraph4(false);
        setShowGraph5(false);
        setShowGraph6(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);
  const fetchData = async () => {
    try {
      const response = await axios.get('https://newm5.hetadatain.com/test/api/delta', {
        params: {
          date: selectedDate.toISOString().slice(0, 10)
        }
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="pop">
      <div ref={popupRef} className="popup">
        <div className="popup-content">
          
          {showGraph1 && data.length > 0 && (
        <Graph_pressure selectedDate={selectedDate} />
      )}
          {showGraph2 && data.length > 0 && (
        <Graph_c_flow selectedDate={selectedDate} />
      )}

          {showGraph3 && data.length > 0 && (
        <Graph_s_flow selectedDate={selectedDate} />
      )}

{showGraph4 && data.length > 0 && (
        <Graph_s_t selectedDate={selectedDate} />
      )}
          {showGraph5 && data.length > 0 && (
        <Graph_r_t selectedDate={selectedDate} />
      )}
        </div>
      </div>
      </div>
    <div className="sup_a">
    <div className="outer-box_a">
      <div className="title_a">Chiller I/P</div>
      <div className="bottom-left-box" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>F</div>
      <div className="bottom-right-box" onClick={handleGraphClick2} style={{ cursor: "pointer" }}>
        {data.map((item) => (
          <div key={item.id}>{item.v11}</div>
        ))}
      </div>
    </div>
    </div>
    <div className="tes">
    <div className="outer-box_tes">
      <div className="title_tes">TES</div>
    </div>
    </div>
    <div className="chiller">
    <div className="outer-box_chiller">
      <div className="title_chiller">Chiller</div>
    </div>
    </div>
    <div className="sup_b">
    <div className="outer-box_b">
      <div className="title_b">Pressure</div>
      <div className="bottom-left-box_b" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>P</div>
      <div className="bottom-right-box_b" onClick={handleGraphClick1} style={{ cursor: "pointer" }}>
        {data.map((item) => (
          <div key={item.id}>{item.v1}</div>
        ))}
      </div>
    </div>
    </div>
    <div className="sup_c">
    <div className="outer-box_c">
      <div className="title_c">Supply</div>
      <div className="bottom-left-box_c">T(°C)</div>
      <div className="bottom-left-box_c" onClick={handleGraphClick3} style={{ cursor: "pointer" }}>Flow</div>
      <div className="bottom-right-box_c">
        {data.map((item) => (
          <div key={item.id} onClick={handleGraphClick3} style={{ cursor: "pointer" }}>{item.v2}</div>
        ))}
      </div>
      <div className="bottom-right-box_c" onClick={handleGraphClick4} style={{ cursor: "pointer" }}>
        {data.map((item) => (
          <div key={item.id} onClick={handleGraphClick4} style={{ cursor: "pointer" }}>{item.v6}</div>
        ))}
      </div>
    </div>
    </div>
    <div className="sup_d">
    <div className="outer-box_d">
      <div className="title_d">Return</div>
      <div className="bottom-left-box_d" onClick={handleGraphClick5} style={{ cursor: "pointer" }}>T(°C)</div>
      <div className="bottom-right-box_d" onClick={handleGraphClick5}style={{ cursor: "pointer" }}>
        {data.map((item) => (
          <div key={item.id}>{item.v7}</div>
        ))}
      </div>
    </div>
    </div>
    
    </div>
  );
};

export default Supply;
